import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Gateway } from 'app/classes/gateway';
import { APIService } from 'app/shared/api.service';

@Component({
  selector: 'app-site-landing',
  templateUrl: './site-landing.component.html',
  styleUrls: ['./site-landing.component.css']
})
export class SiteLandingComponent implements OnInit {

  siteId: number;
  gateways: Gateway[];

  constructor(private http: HttpClient, private apiService: APIService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.siteId = +this.route.snapshot.params['siteid'];
    /*
        this.apiService.getGatewaysForSiteId(this.siteId)
          .then(gateways => {
            this.gateways = gateways.filter(gateway => gateway.hasCharts);

            this.buildCharts();
          });
          */



   // this.buildCharts('00000000f3cace4a', 2020, 1);
  }

/*
  buildCharts(gatewayId, year, week) {
    const url = `https://4d-graph-data.s3.eu-west-2.amazonaws.com/gateways/${gatewayId}/${year}/${week}.json`;

    this.http.get<any>(url).subscribe(rows => {
      //  const header = rows[0].split;

      const chart = c3.generate({
        bindto: '#combined',
        data: {
          x: 'x',
          xFormat: '%Y-%m-%d %H:%M:%S.%L',
          columns: rows
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%d %m'
            },
          }
        },
      });

      setTimeout(() => {
        chart.load({ url, type: times });
      }, 100);
    })
  }
  */
}
