<article>
  @defer (when review()) {
    <section class="no-print toolbar">
      @if (isLoadingData) {
        <div class="loading">
          Loading
          @for (a of assets(); track $index) {
            @if ($index < assetsLoaded()) {
              <i class="mdi mdi-square green"></i>
            } @else {
              <i class="mdi mdi-square gray"></i>
            }
          }
        </div>
      } @else {
        <app-toolbar>
          <div class="left">{{ review()?.review.dates.start | date: 'dd MMM yyyy' }} - {{ review()?.review.dates.end | date: 'dd MMM yyyy' }}</div>
          <div class="right">
            <app-button (click)="printPage()" label="Print Report" icon="mdi mdi-printer" [isCTA]="!outstandingAnnotations" />
            @if (review()?.state !== 'done') {
              <app-button
                label="Delete"
                icon="mdi mdi-trash-can"
                (click)="deleteReview()"
                tooltipPosition="top"
                pTooltip="This review can be deleted as the pdf hasn't been uploaded." />
            } @else {
              <app-button
                label="Delete"
                icon="mdi mdi-alert-outline"
                [isDisabled]="true"
                tooltipPosition="top"
                pTooltip="This review cannot be deleted as the pdf HAS been uploaded." />
            }
            <app-button label="Save" icon="mdi mdi-check" (click)="saveClick()" />
          </div>
        </app-toolbar>
      }
    </section>

    <section class="page-1">
      <div class="print-only">
        <app-insights-as-page1 [building]="review().building" [start]="review().review.dates.start" [end]="review().review.dates.end" />
      </div>
    </section>

    <section [class.is-dragging]="isDragging" (drop)="onDropHandler($event)">
      <main class="drag-message">
        DROP DOCUMENT HERE TO UPLOAD
        <p><app-button icon="mdi mdi-close" (click)="isDragging = false" label="Cancel" /></p>
      </main>

      @for (assetContainer of assets(); track assetContainer.asset.id) {
        <div class="asset asset-{{ assetContainer.asset.id }} mb-1">
          <div class="asset-title"><app-asset-icon [assetTypeId]="assetContainer.asset.assetType_id"></app-asset-icon> {{ assetContainer.asset.title }}</div>
          @if (assetContainer.telemetry?.length) {
            <app-d3-chart
              [asset]="assetContainer.asset"
              [hasToClickToInteract]="true"
              [canAnnotate]="true"
              [canZoom]="false"
              height="200"
              [telemetry]="assetContainer.telemetry"
              [showLegend]="true"
              [minMaxFromData]="true"
              [penWidth]="0.8" />

            <div class="no-print annotation-select">
              <div>Annotation</div>
              <app-select-review-annotation [adminAnnotations]="config().commonAnnotations" (onSelected)="annotationRequested(assetContainer, $event)" />
              <div>
                @for (annotation of config().commonAnnotations; track $index) {
                  <span class="anno-chip" (click)="assetContainer.annotation = { rag: annotation.rag, text: annotation.text }">{{ annotation.text }}</span>
                }
              </div>
            </div>
            <div class="annotation-edit">
              <div class="rag rag-{{ assetContainer.annotation.rag }}" (click)="clickRag(assetContainer)"></div>
              <div>
                <textarea [(ngModel)]="assetContainer.annotation.text" [rows]="1" class="fourd" (change)="annotationChanged()"></textarea>
              </div>
            </div>
          } @else {
            @if (assetContainer.telemetry) {
              <app-info-panel>No Data</app-info-panel>
            } @else {
              <i class="mdi mdi-spin-x2 mdi-loading"></i>
            }
          }
        </div>
      }
    </section>
  } @placeholder {}
</article>
