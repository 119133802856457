import { Component, OnInit, signal } from '@angular/core';
import { Org } from 'app/classes/org';
import { APIService } from 'app/shared/api.service';
import { IGetReviewQuickview, InsightsReviewService, ReviewsForBuildingItemReview } from 'app/shared/insights.review.service';
import moment from 'moment';

@Component({
  selector: 'app-insights-as-list',
  templateUrl: './insights-as-list.component.html',
  styleUrls: ['./insights-as-list.component.css']
})
export class InsightsAsListComponent implements OnInit {

  results: ReviewsForBuildingItemReview[];
  filteredResults: ReviewsForBuildingItemReview[];

  orgs: Org[];
  json: { body: any, qs: any, uuid: any };

  review: ReviewsForBuildingItemReview;
  quickReview: IGetReviewQuickview;

  searchFor: string;
  isLoading = signal(false);

  constructor(private iService: InsightsReviewService, private apiService: APIService) {
    const storageString = localStorage.getItem('insights:assets:list');
    if (storageString) {
      const storageObject = JSON.parse(storageString);
      this.searchFor = storageObject.searchFor;
    }
  }

  ngOnInit(): void {
    if (!this.apiService.isAdmin()) {
      return;
    }

    this.apiService.getOrgs().then(orgs => {
      this.orgs = orgs;
      this.get();

    });
  }

  searchChanged(searchText: string) {
    this.searchFor = searchText;
    localStorage.setItem('insights:assets:list', JSON.stringify({ searchFor: this.searchFor }));
    this.filter();
  }

  filter() {
    if (!this.searchFor || this.searchFor.length < 2) {
      this.filteredResults = this.results;
      return;
    }
    this.filteredResults = this.results.filter(r => {
      if (r.title.toLowerCase().includes(this.searchFor)) return true;
    });
  }

  reviewClick(reviewRow: ReviewsForBuildingItemReview) {
    this.review = reviewRow;
    console.log(reviewRow);
    this.iService.getReviewQuickview(reviewRow.id).then(review => {
      console.log(review);
      review.review.endDate = new Date(review.review.endDate);

      this.quickReview = review;

      this.quickReview.calendarItems = this.quickReview.calendarItems.sort((a, b) => new Date(a.startAt) < new Date(b.startAt) ? 1 : -1);
      const firstIem = this.quickReview.calendarItems.find(c => moment(new Date(c.startAt)).diff(review.review.endDate, 'day') < 14 && moment(new Date(c.startAt)).diff(review.review.endDate, 'day') >= 0);

      if (firstIem) {
        firstIem.isHighlighted = true;
      }

    });
  }

  calendarItemClick(calendarItem: any) {
    console.log(calendarItem);
  }

  get() {
    this.isLoading.set(true);
    this.iService.getReviewsForAdmin(this.orgs).then(r => {
      this.results = r;
      this.filter();
      this.isLoading.set(false);
    });
  }

  getJSON(uuid: string) {
    this.iService.getReviewJSON(uuid).then(r => {
      try {
        this.json = JSON.parse(r);
      } catch (e) {

      }
    });
  }

}
