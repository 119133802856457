import { Component, input, OnInit, output, signal } from '@angular/core';
import { Building } from 'app/classes/building';
import { CollectionAsset } from 'app/classes/collection-for-assets';
import { Gateway } from 'app/classes/gateway';
import { Insight } from 'app/classes/insight';
import { APIService } from 'app/shared/api.service';
import { InsightsService } from 'app/shared/insights.service';
import { IGrandTotals } from './aq-report-type/aq-report-type.component';

@Component({
  selector: 'app-aq-collections-report',
  templateUrl: './aq-collections-report.component.html',
  styleUrl: './aq-collections-report.component.css'
})
export class AqCollectionsReportComponent implements OnInit {

  startDate = input.required<Date>()
  building = input.required<Building>();
  collections = input.required<CollectionAsset[]>();
  useOrgLogo = input<boolean>(true);
  insight: Insight = new Insight();
  onReportGenerated = output<Insight>();
  headerTitle: string;
  isReady = false;
  hasData = false;
  logo: string = '/assets/svg/4d_logo_light.svg';
  isLight: boolean;
  svgLocation = 'assets/svg/';

  grandTotals: IGrandTotals;

  constructor(private apiService: APIService, private insightsService: InsightsService) {

  }

  ngOnInit(): void {

    this.getInsights();
  }

  getInsights() {
    setTimeout(() => {
      window.scrollTo({ top: 1200, behavior: 'smooth' });
    }, 100);

    this.insightsService.getAQInsights(this.building().id, this.collections().map(c => c.id), this.startDate()).then(response => {

      const { collection, grandTotals } = response;
      this.grandTotals = grandTotals;
      this.insight.generatedAt = this.startDate();
      this.insight.configuration = { start: this.startDate(), end: null, assets: null, title: 'AQ Collections Report', types: null, ranges: null };
      this.insight.setGrandGrandTotals(grandTotals);
      this.insight.setTelemetry(collection);

      // this.insight.gateways = this.collections().map(c => new Gateway({ id: c.id, title: c.title, assets: c.assets }));

      this.insight.collectionAssets = this.collections();



      this.headerTitle = this.insight.configuration?.title.split(' from ')[0] || 'AQ Collections Report';

      this.isReady = true;
      this.hasData = true;

      setTimeout(() => {
        const id = 'report';
        const yOffset = -410;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 10);
      this.onReportGenerated.emit(this.insight);
    });
  }
}
