export class OpeningHours {
	hours: OpeningHour[] = [];
	isSet: boolean;

	constructor(data?: any) {
		const shortDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
		const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
		for (let index = 0; index < 7; index++) {
			const dataItem = data?.find(day => day.dow === index);
			if (dataItem && !dataItem.isClosed) {
				// if we have at least one data that is not closed - the hours are set.
				this.isSet = true;
			}
			const hour = new OpeningHour(dataItem);

			hour.day = days[index];
			hour.shortDay = shortDays[index];
			hour.dow = index;
			this.hours.push(hour);
		}
	}

	serialise(complete = false) {
		const hours = this.hours.map(day => {
			if (!day.isClosed) {
				return { dow: day.dow, timeFrom: day.from, timeTo: day.to, isClosed: false };
			} else {
				return { dow: day.dow, isClosed: true };
			}
		});

		return hours;
	}
}

export class OpeningHour {
	isClosed = false;
	from: string;
	to: string;
	day: string;
	shortDay: string;
	dow: number;

	constructor(data?: any) {
		if (data) {
			this.isClosed = !!data.isClosed;
			this.from = data.timeFrom || data.from;
			this.to = data.timeTo || data.to;
			this.dow = data.dow;
			if (this.from) {
				this.from = this.from.substr(0, 5);
			}
			if (this.to) {
				this.to = this.to.substr(0, 5);
			}
		}
	}

	serialise() {
		return { from: this.from, to: this.to, shortDay: this.shortDay, dow: this.dow, day: this.day, isClosed: this.isClosed }
	}
}
