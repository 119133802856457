import { Component, input, signal } from '@angular/core';
import { Site } from 'app/classes/site';

@Component({
  selector: 'app-org-sites',
  templateUrl: './org-sites.component.html',
  styleUrl: './org-sites.component.css'
})
export class OrgSitesComponent {

  sites = input.required<Site[]>();
  tabIndex = signal<number>(0);

  constructor() {

  }

  setTab(tabIndex: number) {
    this.tabIndex.set(tabIndex);
  }
}


