import { Component, HostListener, input, OnDestroy, OnInit, output, signal, computed } from '@angular/core';
import { Asset } from 'app/classes/asset';
import { BuildingReview, BuildingReviewStateType } from 'app/classes/building-review';
import { BuildingReviewConfigItem } from 'app/classes/building-review-config';
import { Telemetry } from 'app/classes/telemetry';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { BuildingsService } from 'app/shared/buildings.service';
import { CollectionsService } from 'app/shared/collections.service';
import { Title } from '@angular/platform-browser';
import { DocumentsService } from 'app/shared/documents.service';

@Component({
  selector: 'app-insights-review-item',
  templateUrl: './insights-review-item.component.html',
  styleUrl: './insights-review-item.component.css'
})
export class InsightsReviewItemComponent implements OnInit, OnDestroy {
  uuid = crypto.randomUUID();
  review = input.required<BuildingReview>();
  config = input.required<BuildingReviewConfigItem>();
  reviewState = signal<BuildingReviewStateType>(null);

  isLoadingData = true;
  assetCount: number;
  assetsLoaded = signal<number>(0)

  message = signal<string>('');
  assets = signal<IAssetContainer[]>(null);
  commonAnnotations: { rag: string, annotation: string }[];
  viewMode: 'edit' | 'print' = 'edit';
  outstandingAnnotations = true;

  onClose = output<boolean>();

  hasDropped: boolean;
  isDragging: boolean;
  uploadFile: File;

  @HostListener("dragenter", ["$event"])
  onDragStartHandler(event: DragEvent) {
    if (this.hasDropped) {
      return;
    }
    this.isDragging = true;
    event.dataTransfer.dropEffect = "copy";
    event.preventDefault();
    console.log("dragstart", event);
  }

  @HostListener("dragover", ["$event"])
  onDragOverHandler(event: DragEvent) {
    if (this.hasDropped) {
      return;
    }
    this.isDragging = true;
    event.dataTransfer.dropEffect = "copy";
    event.preventDefault();
  }

  @HostListener("dragleave", ["$event"])
  onDragLeaveHandler(event: any) {
    if (event.toElement.localName === "td") {
      return;
    }
    //this.isDragging = false;
    event.preventDefault();
    console.info("dragleave", event.toElement.localName, event);
  }

  @HostListener("drop", ["$event"])
  onDropHandler(event: DragEvent) {
    if (!this.isDragging) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    console.log("drop", event);
    this.isDragging = false;
    const file = event.dataTransfer.files[0];
    const { name, type, size, lastModified } = file;

    this.uploadFile = file;

    if (!name || name.toLowerCase().substr(name.length - 3) !== "pdf") {
      return this.apiService.toastWarn("Not a PDF", `received ${name}`);
    }

    this.hasDropped = true;

    const building = this.review().building;
    const review = this.review().review;
    this.apiService.toastSuccess("uploading", "please wait...");
    const title = 'Building Review for ' + building.title + ' from ' + review.dates.start.toLocaleDateString() + ' to ' + review.dates.end.toDateString();

    const filename = this.titleService.getTitle();
    this.apiService.postUploader(title, false, 'building_reviews', `${building.id}_${this.apiService.dbDate(review.dates.start)}`, this.uploadFile.type, filename, this.uploadFile).then((r) => {
      console.log(r);
      this.apiService.toastSuccess("uploaded", "");
      this.hasDropped = false;
      this.isDragging = false;
    });
  }

  constructor(private documentService: DocumentsService, private titleService: Title, private apiService: APIService, private buildingService: BuildingsService, private collectionService: CollectionsService, private assetService: AssetService) {

  }

  annotationChanged() {
    console.log('annotationChanged()');
    this.outstandingAnnotations = this.assets().some(a => a.annotation?.text?.length < 1);
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(`4D Portal`);
  }

  ngOnInit(): void {
    this.initialiseReview();
    this.titleService.setTitle(`u_${this.apiService.getUserId()}_b_${this.review().building.id}_d_${+this.review().review.dates.start}_review.pdf`);
  }

  async saveClick() {
    await this.buildingService.updateReviewAnnotationsForBuilding(this.review().building.id, this.review().review.dates.start, this.assets());
    this.apiService.toastSuccess('Updated annotations', '');
  }

  async deleteReview() {
    this.apiService.toastSuccess('Delete in progress...', '');
    await this.buildingService.deleteReviewForBuilding(this.review().building.id, this.review().review.dates.start);
    this.apiService.toastSuccess('Deleted', '');
    this.onClose.emit(true);
  }

  clickRag(asset: IAssetContainer) {
    let rag = asset.annotation.rag;
    switch (rag) {
      case 'green':
        rag = 'amber';
        break;
      case 'amber':
        rag = 'red';
        break;
      default: // handles null to green as well as red
        rag = 'green';
        break;
    }
    asset.annotation.rag = rag;
  }

  getDates() {
    const start = this.review().review.dates.start || this.config().review.next.start;
    const end = this.review().review.dates.end || this.config().review.next.end;

    return { start, end };
  }

  initialiseReview() {
    const { start, end } = this.getDates();

    this.buildingService.initialiseReview(this.config().building.id, start, end, this.config().collection.id).then(status => {
      this.assets.set(status.assets.map(a => { return { asset: new Asset({ id: a.id, title: a.title, assetType_id: a.assetType_id }), telemetry: null, state: 'pending', annotation: { rag: 'green', text: '' } } }));
      console.log('Review loaded');

      this.assetCount = this.assets.length;
      this.assetsLoaded.set(0);

      if (status?.annotations) {
        status.annotations.forEach(a => {
          const asset = this.assets().find(asset => asset.asset.id === a.asset_id);
          if (asset) {
            asset.annotation = { rag: a.rag, text: a.annotation };
          }
        });
        this.annotationChanged();
      }

      this.getTelemetry();
    });
  }

  printPage() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.print();
    }, 100);
  }

  getTelemetry() {
    const getAsset = this.assets().find(a => a.state === 'pending');

    if (!getAsset) {
      console.log('All done!');
      // this.apiService.toastSuccess('All data received', '');
      this.assetsLoaded.set(this.assetCount);
      this.isLoadingData = false;
      return;
    }

    this.assetService.getTelemetry(getAsset.asset.id, this.review().review.dates.start, this.review().review.dates.end).then((response: any) => {
      getAsset.state = 'hasdata';
      this.assetsLoaded.set(this.assetsLoaded() + 1);

      this.assets.update(assets => {
        const index = assets.findIndex(a => a.asset.id === getAsset.asset.id);
        assets[index] = { ...getAsset, telemetry: response.telemetry };

        return assets;
      });

      setTimeout(() => {
        this.getTelemetry();
      }, 10);
    })
  }

  annotationRequested(asset: IAssetContainer, annotation: any) {
    console.log(asset, annotation);
    this.assets.update(assets => {
      const index = assets.findIndex(a => a.asset.id === asset.asset.id);
      assets[index] = {
        ...asset, annotation: { rag: annotation.rag, text: annotation.text }
      };

      return assets;
    });
    this.annotationChanged();
  }

  getSupporting() {

  }
}

export interface IAssetContainer {
  asset: Asset;
  annotation?: { rag: string, text: string };
  telemetry: Telemetry[];
  state: 'pending' | 'getting' | 'nodata' | 'hasdata';
}
