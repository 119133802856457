<app-theme-page-section [label]="'New Gateways'"  [toolbar]="[
{icon:'mdi-table-row-plus-after',label:'Add Identifier',tag:'ADD_ROW'}
]" (onToolbarClick)="toolbarClick($event)" >
    <div *ngIf="!settings">Loading setttings...</div>
    <main *ngIf="settings" class="{{mode}}">
        <section *ngFor="let setting of settings | keyvalue">
            <div>
                <table class="table" [class.table-hover]="mode==='edit'">
                    <thead>
                        <tr>
                            <th>{{setting.key}}</th>
                            <th>Identifier</th>
                            <th tooltipPosition="left" pTooltip="Added as asset on new gateways">Default</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of settings[setting.key]" (click)="rowClicked(setting.key, item)">
                            <td> <app-asset-icon [assetTypeId]="item.assetType_id"></app-asset-icon> {{item.title}}</td>
                            <td>{{item.identifier}}</td>
                          <!-- <td *ngIf="mode === 'edit'">
                               <app-tick-button theme="none" [(selected)]="item.isDefault"></app-tick-button>
                            </td>-->
                            <td class="text-center"><span *ngIf="item.isDefault">Yes</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </section>
    </main>

    <div>
        <i *ngIf="org.id === 4">You are in the master record, this is the default for orgs that do not override this.</i>
    </div>
</app-theme-page-section>

<div class="_modal0" *ngIf="settingSelected"></div>

<div class="_modal" *ngIf="settingSelected">
	<div class="modal-container">
        <div>
            <label >Product</label>
            <select [disabled]="!settingSelected.isNew" [(ngModel)]="settingSelected.setting" class="form-control">
                <option value="airly">Airly</option>
                <option value="airthings">Airthings</option>
                <option value="awair">Awair</option>
            </select>
            <label>Title</label>
            <input [(ngModel)]="settingSelected.item.title" class="form-control">
            <label>Identifier</label>
            <input [(ngModel)]="settingSelected.item.identifier" class="form-control">
            <label>Asset Type</label>
            <!--<input [(ngModel)]="settingSelected.item.assetType_id" class="form-control">-->
            <select [(ngModel)]="settingSelected.item.assetType_id" class="form-control">
                <option *ngFor="let option of assetTypes" [value]="option.id">{{option.title}}</option>
            </select>
            <label>Created on new Gateway</label>
            <select [(ngModel)]="settingSelected.item.isDefault" class="form-control">
                <option [value]="1">Yes</option>
                <option [value]="0">No</option>
            </select>
        </div>

        <div class="mt-1 buttons">
            <button *ngIf="!settingSelected.isNew" class="btn btn-danger  ml-1" (click)="deleteSetting()">Delete</button>
            <button class="btn btn-secondary  ml-1" (click)="settingSelected=null;">Cancel</button>
            <button class="btn btn-primary  ml-1" (click)="updateSetting()">Save</button>
        </div>
	</div>
</div>
