<app-theme-page-container>
  <main>
    <app-theme-page-section>
      <div>
        <h2>Indoor Air Quality</h2>
        <section>
          Generate your buildings indoor air quality report, analysing sensor data from multiple gateways. The report helps you gain insights into indoor air
          quality and helps identify areas requiring attention.
        </section>
      </div>
      <footer>
        <!--<app-button routerLink="airquality" label="Awair/Airthings" />-->
        <app-button routerLink="airquality" [queryParams]="{ collections: true }" label="Generate report" />
      </footer>
    </app-theme-page-section>

    <app-theme-page-section *ngIf="can.footfall">
      <div>
        <h2>Footfall</h2>
        <section>Generate your monthly footfall report. The report shows daily footfall over a month, with % change over previous weeks.</section>
        <section *ngIf="can.admin" class="admin-note"><i class="fa fa-lightbulb-o"></i> Footfall needs to be enabled per user.</section>
      </div>
      <footer><app-button routerLink="footfall">Generate report</app-button></footer>
    </app-theme-page-section>

    <app-theme-page-section *ngIf="can.review" logo="admin" tooltipPosition="bottom">
      <div>
        <h2><i class="mdi mdi-account-lock"></i> Building Review <small>for Administrators</small></h2>
        <section>
          <p>Generate quarterly building reviews, the review reveals the health of a building, comparing the performance of equipment.</p>
        </section>
      </div>
      <footer>
        <app-button routerLink="assets/list" class="mr-1">List reports</app-button>
        <app-button routerLink="assets" pTooltip="Build or update a report">Build report V1</app-button>
        <app-button routerLink="reviews" tooltipPosition="left" pTooltip="Version 2, re-write in development">Build report V2</app-button>
      </footer>
    </app-theme-page-section>
  </main>
</app-theme-page-container>
