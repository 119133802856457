@if(user) {
<main [class.is-admin]="isAdmin">
    <div class="fourdtabs">
        @if(can().sites) {
        <div (click)="setTab('sites')" [class.active]="tabId()==='sites'">
            <span><i class="fa fa-building"></i> <span class="isDesktop">&nbsp;Sites</span></span>
        </div>
        }
        @if(can().contacts) {
        <div (click)="setTab('contacts')" [class.active]="tabId()==='contacts'">
            <span><i class="fa fa-user"></i> <span class="isDesktop">&nbsp;Users</span></span>
        </div>
        }
        @if(can().tenants) {
        <div (click)="setTab('tenants')" [class.active]="tabId()==='tenants'">
            <span><i class="mdi mdi-account-card-outline"></i> <span class="isDesktop">&nbsp;Tenants</span></span>
        </div>
        }
        @if(can().setpoints) {
        <div (click)="setTab('setpoints')" [class.active]="tabId()==='setpoints'">
            <span><i class="mdi mdi-bullseye"></i> <span class="isDesktop"> Setpoints</span></span>
        </div>
        }
        @if(can().billing) {
        <div (click)="setTab('billing')" [class.active]="tabId()==='billing'">
            <span><i class="mdi mdi-certificate-outline"></i> <span class="isDesktop">&nbsp;Billing</span></span>
        </div>
        }
        @if(can().occupancy) {
        <div (click)="setTab('occupancy')" [class.active]="tabId()==='occupancy'">
            <span><i class="mdi mdi-motion-sensor"></i> <span class="isDesktop">&nbsp;Occupancy</span></span>
        </div>
        }
        @if(can().integration) {
        <div (click)="setTab('settings')" [class.active]="tabId()==='settings'">
            <span><i class="mdi mdi-cog"></i> <span class="isDesktop">&nbsp;Settings</span></span>
        </div>
        }
        @if(can().swap) {
        <div (click)="setTab('swap')" [class.active]="tabId()==='swap'">
            <span><i class="fa fa-unlock"></i> <span class="isDesktop">&nbsp;Switch</span></span>
        </div>
        }
        @if(can().upload) {
        <div (click)="setTab('upload')" [class.active]="tabId()==='upload'">
            <span><i class="mdi mdi-camera"></i> <span class="isDesktop">&nbsp;Camera</span></span>
        </div>
        }
        <!-- FOR FUTURE USE
        @if(can().export) {
        <div (click)="setTab('export')" [class.active]="tabId()==='export'">
            <span><i class="mdi mdi-email-newsletter"></i> <span class="isDesktop">&nbsp;Export</span></span>
        </div>
        }
        -->
        <div></div>
    </div>
    <div class="content-container">
        @if(tabId()=== 'map') {

        }
        @if(tabId()=== 'sites') {
        <app-org-sites [sites]="sitesFiltered"></app-org-sites>
        }
        @if(tabId()==='contacts') {
        <app-theme-page-container>

            <div class="tabview-list">
                <div [class.active]="userTab()===0" (click)="userTab.set(0)" pTooltip="Groups of people" tooltipPosition="top"><i class="mdi mdi-account-multiple-outline"></i> Groups </div>
                <div [class.active]="userTab()===1" (click)="userTab.set(1)" pTooltip="People who are not 4D users" tooltipPosition="top"><i class="mdi mdi-card-account-details-outline"></i> Contacts </div>
                <div [class.active]="userTab()===2" (click)="userTab.set(2)" pTooltip="People who can login to 4D"><i class="mdi mdi-account-outline"></i> Portal users </div>
                <div> </div>

            </div>
            <div class="tabview-content">
                @switch(userTab()) {
                @case(0) {
                <app-org-group-home></app-org-group-home>
                }
                @case(1) {
                <app-org-contacts-home></app-org-contacts-home>
                }
                @default() {
                <app-org-users-home></app-org-users-home>
                }
                }
            </div>

        </app-theme-page-container>
        }
        @if(tabId()=== 'tenants') {
        <app-org-tenants [org]="org" />
        }
        @if(tabId()=== 'setpoints') {
        <app-org-setpoints />
        }
        @if(tabId()=== 'billing') {
        <app-billing-home></app-billing-home>
        }
        @if(tabId()=== 'occupancy') {
        <app-occupancy-admin></app-occupancy-admin>
        }
        @if(tabId()=== 'settings') {
        <p-tabView>
            <p-tabPanel [cache]="true" *ngIf="can().orgAdmin">
                <ng-template pTemplate="header">
                    <span><i class="mdi mdi-wifi"></i> <span class="isDesktop">&nbsp;Sensors</span></span>
                </ng-template>
                <ng-template pTemplate="content">
                    <h4>Sensors</h4>
                    <app-org-settings></app-org-settings>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel [cache]="true" *ngIf="can().compliance">
                <ng-template pTemplate="header">
                    <span><i class="mdi mdi-security"></i> <span class="isDesktop">&nbsp;Compliance</span></span>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-org-compliance-home></app-org-compliance-home>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel [cache]="true" *ngIf="can().integration">
                <ng-template pTemplate="header">
                    <span><i class="mdi mdi-application-import"></i> <span class="isDesktop">
                            Integration</span></span>
                </ng-template>
                <ng-template pTemplate="content">
                    <div>
                        <h4>Integration</h4>
                        <app-org-integration-home></app-org-integration-home>
                    </div>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
        }
        @if(tabId()=== 'swap') {
        @if(isAdmin) {
        <app-org-switch [orgsIn]="orgs"></app-org-switch>
        } @else {
        <p>Not available.</p>
        }
        }
        @if(tabId()=== 'upload') {
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <span><i class="fa fa-list"></i> Uploads
                        &nbsp;<i class="fa fa-refresh" (click)="refreshCameraList()" pTooltip="refresh"></i>
                    </span>
                </ng-template>
                <app-system-upload-list *ngIf="isListAvailable"></app-system-upload-list>
            </p-tabPanel>

            <p-tabPanel>
                <ng-template pTemplate="header">
                    <span><i class="fa fa-camera"></i> Take picture</span>
                </ng-template>
                <app-system-upload (onDidCapture)="onDidCapture($event)"></app-system-upload>
            </p-tabPanel>
        </p-tabView>
        }
        @if(tabId()=== 'export') {
        <p>For future use</p>
        }
    </div>
</main>
}

@if(isWorking()) {
<app-dialog [dimensions]="{'width':200, 'height':90}" [canInteract]="false">
    <h2>Switching org...</h2>
</app-dialog>
}
