import { Injectable } from '@angular/core';
import { Collection } from 'app/classes/collection';
import { Asset } from 'app/classes/asset';

export class UserCollection {
  collection: Collection<Asset>;
  title: string;
  id: number;
}

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {
  collections: Collection<UserCollection>

  constructor() {
    this.collections = new Collection<UserCollection>();
  }


}
