<article class="content-container">
  <section>
    <h2>Creating a new Building Review</h2>
    <ul>
      <li>
        Click "Create Review (due)" to choose the starting month for the report, and select the number of months. Suggested defaults will be presented based on
        the previous report generated.
      </li>
      <li>Once the data is loaded, annotate each asset. Use the dropdown for common annotations. Remember to save the annotations when you're done.</li>
      <!--   <li>The report will auto save if you leave the report, or you can manually save at any point.</li> -->
      <li>
        Once completed, print the page and save to your computer as a pdf, then drag the pdf from your local folder to the building review web page you just
        printed. This will upload it to that review and make it available to 4D clients.
      </li>
    </ul>
  </section>
</article>
