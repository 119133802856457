import { Component, OnInit } from '@angular/core';
import { AssetType } from 'app/classes/asset';
import { Org } from 'app/classes/org';
import { APIService } from 'app/shared/api.service';
import { AssetService } from 'app/shared/asset.service';
import { IToolbarItem } from 'app/theme/theme-page-section/theme-page-section.component';
import { OrgService } from "../../shared/org.service";
@Component({
  selector: 'app-org-settings',
  templateUrl: './org-settings.component.html',
  styleUrls: ['./org-settings.component.css']
})
export class OrgSettingsComponent implements OnInit {

  settings: ISettings;
  /*{
    airthings: [
      { title: 'CO2', assetType_id: 25, identifier: 'co2', isDefault: true },
      { title: 'Humidity', assetType_id: 8, identifier: 'humidity', isDefault: true },
      { title: 'Light', assetType_id: 7, identifier: 'light', isDefault: true },
      { title: 'PM 2.5', assetType_id: 29, identifier: 'pm25', isDefault: false },
      { title: 'PM 1', assetType_id: 62, identifier: 'pm1', isDefault: false },
      { title: 'Pressure', assetType_id: 3, identifier: 'pressure', isDefault: true },
      { title: 'Sound', assetType_id: 49, identifier: 'soundLevelA', isDefault: false },
      { title: 'Temperature', assetType_id: 2, identifier: 'temp', isDefault: true },
      { title: 'Virus Risk', assetType_id: 54, identifier: 'virusRisk', isDefault: true },
      { title: 'VOC', assetType_id: 27, identifier: 'tvoc', isDefault: true },
    ],
    airly:[ 
      { title: 'Outdoor Temperature', assetType_id: 2, identifier: 'outdoorTemp', isDefault: true },
      { title: 'Outdoor Humidity', assetType_id: 8, identifier: 'outdoorHumidity', isDefault: true },
      { title: 'Outdoor PM 1', assetType_id: 62, identifier: 'outdoorPm1', isDefault: true },
      { title: 'Outdoor PM 2.5', assetType_id: 29, identifier: 'outdoorPm25', isDefault: true },
      { title: 'Outdoor PM 10', assetType_id: 30, identifier: 'outdoorPm10', isDefault: true },
      { title: 'Outdoor No2', assetType_id:61, identifier: 'outdoorNo2', isDefault: true },
      { title: 'Outdoor Pressure', assetType_id: 3, identifier: 'outdoorPressure', isDefault: true },
      { title: 'Outdoor Nitric Oxide', assetType_id: 63, identifier: 'outdoorNo', isDefault: true },
    ],
    awair: [
      { title: 'Temperature', assetType_id: 2, identifier: 'temp', isDefault: true },
      { title: 'Humidity', assetType_id: 8, identifier: 'humid', isDefault: true },
      { title: 'CO2', assetType_id: 25, identifier: 'co2', isDefault: true },
      { title: 'Light', assetType_id: 7, identifier: 'lux', isDefault: true },
      { title: 'VOC', assetType_id: 27, identifier: 'voc', isDefault: true },
      { title: 'Sound', assetType_id: 49, identifier: 'spl_a', isDefault: true },
      { title: 'Score', assetType_id: 28, identifier: 'score', isDefault: true },
      { title: 'PM2.5', assetType_id: 29, identifier: 'pm25', isDefault: true },
      { title: 'PM10', assetType_id: 28, identifier: 'pm10', isDefault: false }
    ]
  };*/

  mode: 'view' | 'edit' = 'edit';
  org: Org;

  settingSelected: { setting: string, item: ISettingItem, isNew: boolean };

  assetTypes: AssetType[];

  constructor(private orgService: OrgService, private apiService: APIService, private assetService: AssetService) {
    this.org = apiService.getUserOrg();
  }

  ngOnInit(): void {
    this.orgService.getDefaultSensors().then(r => {
      this.settings = r;
      
      this.sort();
    });
  }
  sort() {
    if (!this.settings?.airly || !this.settings?.airthings ) {
      return;
    }
    this.settings.airly = this.settings.airly.sort((a, b) => a.title > b.title ? 1 : -1);
    this.settings.airthings = this.settings.airthings.sort((a, b) => a.title > b.title ? 1 : -1);
    this.settings.awair = this.settings.awair.sort((a, b) => a.title > b.title ? 1 : -1);
  }

  toolbarClick(event: IToolbarItem) {
    console.log(event);
    switch (event.tag) {
      case 'MODE':
        this.mode = this.mode === 'view' ? 'edit' : 'view';
        break;
      case 'ADD_ROW':
        this.settingSelected = { isNew: true, setting: null, item: { assetType_id: null, title: '', identifier: '', isDefault: 1 } };
        break;
    }
  }

  updateSettings() {
    this.orgService.updateDefaultSensors(this.settings).then(r => {
      console.log(r);
    });
  }

  rowClicked(setting: string, item: ISettingItem) {
    if (this.mode === 'view') {
      return;
    }
    this.assetTypes = this.assetService.getAssetTypes().sort((aa, bb) => aa.title > bb.title ? 1 : -1);
    this.settingSelected = { setting, item, isNew: false };
  }

  deleteSetting() {

  }

  updateSetting() {

  }
}

export interface ISettings {
  airthings: ISettingItem[],
  awair: ISettingItem[],
  airly: ISettingItem[]
}

export interface ISettingItem {
  title: string;
  assetType_id: number;
  identifier: string;
  isDefault: number;
}
