<section class="no-print fourd-design content-container">
    <h2>Indoor Air Quality</h2>
    <p>
        Insights into a buildings indoor air quality, analysing sensor data from multiple gateways.
    </p>
</section>

@defer(when buildings) {
<section class="fourd-design content-container">
    <div class="no-print">
        @if(isGettingBuilding()) {
        <app-button [label]="building()?.title || 'Please wait'" [isDisabled]="true" [isMuted]="true" icon="mdi mdi-loading mdi-spin-x2" iconPos="right" />
        } @else {
        <app-button [label]="building()?.title || 'Select Building'" (click)="dialogOpen.set('buildings')"> </app-button>
        }
        @if(dialogOpen() === 'buildings') {
        <app-dialog header="Select a building" [canScroll]="true" (onClose)="buildingChange(null)">
            <table class="table table-hover" aria-label="Buildings">
                <thead>
                    <tr>
                        <th colspan="1">Building</th>
                        <th colspan="3">Address</th>
                    </tr>
                </thead>
                <tbody>
                    @for(building of previousBuildings();track building.id) {
                    <tr class="not-selectable">
                        <td colspan="4">Previous building</td>
                    </tr>
                    <tr (click)="buildingChange({id:building.id, title:building.title})">
                        <td>{{building.title}}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr class="not-selectable">
                        <td colspan="4">
                            All Buildings
                        </td>
                    </tr>
                    }
                    @for(building of buildings;track building.id) {
                    <tr (click)="buildingChange(building)">
                        <td>{{building.title}}</td>
                        <td>{{building.site.address.addressTown}}</td>
                        <td>{{building.site.address.addressCounty}}</td>
                        <td>{{building.site.address.addressPostcode}}</td>
                    </tr>
                    }
                </tbody>
            </table>
        </app-dialog>
        }
    </div>

    @if(buildingForReport) {
    <div class="mt-1 no-print">
        <table class="table" aria-label="Buildings">
            <thead>
                <tr>
                    <th>Building</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{buildingForReport.title}}</td>
                    <td>{{buildingForReport.site.address.address1}}</td>
                </tr>
            </tbody>
        </table>

        <div>
            <app-theme-page-section label="Building hours" [toolbar]="[{icon:'mdi-pencil',label:'Edit hours',tag:'EDIT_HOURS'}]" (onToolbarClick)="toolbarClick($event)">
                @if(buildingForReport.openingHours.isSet) {
                <table class="table">
                    <thead>
                        <tr>
                            <th>Monday</th>
                            <th>Tuesday</th>
                            <th>Wednesday</th>
                            <th>Thursday</th>
                            <th>Friday</th>
                            <th>Saturday</th>
                            <th>Sunday</th>
                        </tr>
                    </thead>
                    <tr>
                        <td *ngFor="let day of buildingForReport.openingHours.hours">
                            <span *ngIf="!day.isClosed">{{day.from}} - {{day.to}}</span>
                            <span *ngIf="day.isClosed ">Closed</span>
                        </td>
                    </tr>
                </table>
                } @else {
                <div>
                    <p>No hours set for the building, please update the building hours to continue.</p>

                    <p class="mt-1">
                        <p-button label="Update building hours" icon="mdi mdi-calendar-edit" styleClass="p-button-sm" (click)="toolbarClick('EDIT_HOURS')">
                        </p-button>
                    </p>
                </div>
                }
            </app-theme-page-section>
        </div>
    </div>
    } @else {

    }

    @if(buildingForReport?.openingHours.isSet && combinedCollections()?.length) {
    <section class="no-print">
        <h1>Rooms</h1>
        <table class="table table-hover" aria-label="Gateways as collections">
            <thead>
                <tr>
                    <th>Collection</th>
                </tr>
            </thead>
            <tbody>
                @for(collection of combinedCollections();track collection.id) {
                <tr (click)="collection.selected = !collection.selected">
                    <td><span class="mdi" [class.mdi-checkbox-marked-outline]="collection.selected" [class.mdi-checkbox-blank-outline]="!collection.selected"></span> {{collection.title}}</td>
                </tr>
                }
            </tbody>
        </table>
        @if(!gateways) {
        <app-theme-page-section label="Calendar" class="mt-1">
            <p-calendar view="month" [(ngModel)]="month" dateFormat="smmm yyyy" [inline]="true" [maxDate]="today"></p-calendar>
            <div (click)="useOrgLogo = !useOrgLogo" class="can-click mt-1">
                <span class="mdi" [class.mdi-checkbox-marked-outline]="useOrgLogo" [class.mdi-checkbox-blank-outline]="!useOrgLogo"></span> Use logo for {{org.title}}
            </div>
        </app-theme-page-section>
        }

    </section>
    }

    @if(buildingForReport?.openingHours.isSet && gatewaysNotAvailable()?.length) {
    <section>
        <h1>Gateways with no mappings</h1>
        <table class="table table-hover" aria-label="Gateways unavailable">
            <thead>
                <tr>
                    <th>Gateway</th>
                </tr>
            </thead>
            <tbody>
                @for(gateway of gatewaysNotAvailable();track gateway.id) {
                <tr (click)="clickGatewaysNotAvailable(gateway)">
                    <td>{{gateway.title}}</td>
                </tr>
                }
            </tbody>
        </table>
    </section>

    }

    @if(buildingForReport?.openingHours.isSet && gateways) {
    <section class="no-print select-gateway-calendar">
        <app-theme-page-section label="Gateways" class="mt-1">
            <div *ngFor="let g of gateways" class="gateway" (click)="g.selected = !g.selected" [class.is-selected]="g.selected">
                <div class="mr-1"><span class="mdi" [class.mdi-checkbox-marked-outline]="g.selected" [class.mdi-checkbox-blank-outline]="!g.selected"></span></div>
                <div class="mr-1">
                    @if(g.gatewayIconUrl) {
                    <img [src]="g.gatewayIconUrl | safeUrl">
                    }
                </div>

                <div>{{g.title}}</div>
            </div>
        </app-theme-page-section>
        <app-theme-page-section label="Calendar" class="mt-1">
            <p-calendar view="month" [(ngModel)]="month" dateFormat="smmm yyyy" [inline]="true" [maxDate]="today"></p-calendar>
            <div (click)="useOrgLogo = !useOrgLogo" class="can-click mt-1">
                <span class="mdi" [class.mdi-checkbox-marked-outline]="useOrgLogo" [class.mdi-checkbox-blank-outline]="!useOrgLogo"></span> Use logo for {{org.title}}
            </div>
        </app-theme-page-section>
    </section>
    }
    @if(buildingForReport?.openingHours.isSet ) {
    <section class="no-print actions">

        <div class="mt-1 mb-1 text-center">

            <p-splitButton [disabled]="runReport && !reportWasGenerated" label="Generate Report" icon="mdi mdi-chart-areaspline-variant" (onClick)="generate()" [model]="generateItems"></p-splitButton>

            <button *ngIf="reportWasGenerated" pButton (click)="printPage()" label="Print Report" icon="mdi mdi-printer" class="p-button-secondary"></button>
        </div>
        <div *ngIf="reportWasGeneratedAt" class="text-right gray">Report generated: {{reportWasGeneratedAt | amDateFormat:'DD MMM YYYY HH:mm'}}</div>
    </section>
    }

    @if(runReport) {
    <section>
        <div class="mt-1">
            @if(month && buildingForReport) {
            <app-insights-airquality-report [building]="buildingForReport" [startDate]="month" [gateways]="gatewaysSelected" [collections]="combinedCollections()" [useOrgLogo]="useOrgLogo" (onReportGenerated)="onReportWasGenerated($event)" [clearCache]="clearCache" />
            }
        </div>
    </section>
    }
    @if(gateway()) {
    <app-dialog (onClose)="gateway.set(null)" [canScroll]="true" [header]="gateway().title">
        <app-iac-gateway [gateway]="gateway()" (onUseAssets)="useAssetsForGateway(gateway(), $event)"></app-iac-gateway>
    </app-dialog>
    }
</section>
} @placeholder {
<div class="content-container">
    <i class="mdi-loading mdi mdi-spin-x2"></i>
</div>
}
