import { Asset } from "./asset";
import { Building } from "./building";
export class BuildingReviewConfigItem {
	building: Building;
	collection: { id: number, title: string, assets: Asset[] };
	noOfMonths: number;
	notes: string;
	review: { next: { start: Date, end: Date, title: string, isOverdue: boolean }, noOfMonths: number };
	commonAnnotations: { rag: string, text: string }[];
	collections: { id: number, title: string, assetCount: number }[];

	constructor(data: any) {
		if (!data) {
			return;
		}
		this.building = new Building(data.building);
		this.collection = data.collection;
		this.review = data.review ?? { next: { start: null, end: null, title: 'No previous review', isOverdue: false }, noOfMonths: 3 };
		this.review.next.isOverdue = (new Date(this.review.next?.end) < new Date());
		this.collection.assets = data.assets.map(a => new Asset(a));
		this.commonAnnotations = data.commonAnnotations.map(ca => { return { rag: ca.rag, text: ca.annotation } });
		this.collections = data.collections;
		this.noOfMonths = data.noOfMonths;
		this.notes = data.notes;
		if (this.review.next.start) {
			this.review.next.start = new Date(this.review.next.start);

			if (this.review.next.end) {
				this.review.next.end = new Date(this.review.next.end);
			}

			this.review.noOfMonths = 1 + (this.review.next.end.getFullYear() - this.review.next.start.getFullYear()) * 12 + (this.review.next.end.getMonth() - this.review.next.start.getMonth());
		} else {
			this.review.noOfMonths = 3;
		}
	}

	setNextReviewDate(date: Date) {
		this.review.next.start = date;
		this.review.next.end = null;
		this.review.noOfMonths = this.noOfMonths;
	}
}
