import { Asset } from "./asset";
import { Building } from "./building";

export class BuildingReview {

	state: BuildingReviewStateType;
	title = 'New Review'
	review: { dates: { start: Date, end: Date } };
	createdBy: { id: number, name: string };
	building: Building;
	collection: { id: number, assets: Asset[] };

	/**
	 * 
	 * @param data	An existing review already saved
	 * @returns 
	 */
	constructor(data?: any) {
		if (!data) {
			return;
		}

		if (!data.state) {
			// Use the createNewReview for new reviews
			return;
		}

		this.state = data.state;
		this.building = data.building;
		this.collection = data.collection;
		this.review = { dates: { start: data.start, end: data.end } };
		this.createdBy = data.user;
		this.title = `${data.start.toLocaleDateString()} to ${data.end.toLocaleDateString()}`;
	}

	createNewReview(building: Building, start: Date, end: Date, collection: { id: number, assets: Asset[] }) {
		this.building = building;
		this.collection = collection;
		this.review.dates = { start, end }
		this.createdBy = { id: null, name: null };
	}

	serialise() {
		return {
			title: this.title,
			review: this.review,
			building: this.building?.id,
			collectionId: this.collection?.id
		};
	}
}

export type BuildingReviewStateType = 'new' | 'init' | 'active' | 'done';
